import React from 'react';
import cssClasses from './plans.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import backGround from '@/assets/new-assets/home/pricingBg.webp';
import Tick from '@/assets/new-assets/home/Tick.webp';

// import DiscountSvg from '@/assets/discount.svg';
import crown from '@/assets/new-assets/home/crown.webp';
import Image from 'next/image';
import { cn } from '@/utils';
import { useMobileResponsive } from '@/hooks';

type PricingType = {
  price: number;
  isBestDeal: boolean;
  mostPopular: boolean;
  paymentType: string;
  discountPercent: number;
};

const pricing: { [key: string]: PricingType[] } = {
  DROID: [
    // {
    //   price: 299,
    //   isBestDeal: false,
    //   mostPopular: false,
    //   paymentType: `monthly`,
    //   discountPercent: 0,
    // },
    {
      price: 1194,
      isBestDeal: false,
      mostPopular: true,
      paymentType: `6-months`,
      discountPercent: 0,
    },
    {
      price: 1788,
      isBestDeal: true,
      mostPopular: false,
      paymentType: `annually`,
      discountPercent: 30,
    },
  ],

  IOS: [
    // {
    //   price: 299,
    //   isBestDeal: false,
    //   mostPopular: false,

    //   paymentType: `monthly`,
    //   discountPercent: 0,
    // },

    {
      price: 1194,
      isBestDeal: false,
      mostPopular: true,

      paymentType: `6-months`,
      discountPercent: 0,
    },
    {
      price: 1799,
      isBestDeal: true,
      mostPopular: false,

      paymentType: `annually`,
      discountPercent: 30,
    },
  ],
};

const ArrowIcon = () => {
  return (
    <div className="w-9 h-9">
      <Image src={Tick} width={36} height={36} alt="Arrow Icon" />
    </div>
  );
};

export function PricingPlans() {
  const [activePlan, setActivePlan] = React.useState<'DROID' | 'IOS'>(`DROID`);

  const { isMobile } = useMobileResponsive();

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${backGround.src})`,
          backgroundSize: `cover`, // Cover the entire div
          backgroundPosition: `center`, // Center the background image
          backgroundRepeat: `no-repeat`, // Prevent repeating the image
        }}
        className={cn(
          ` text-white mt-40 bg-level-purple m-auto relative z-30 overflow-hidden  `,
        )}
      >
        <div className="absolute top-0   w-full h-20 md:h-52  bg-gradient-to-b  from-level-purple to-transparent opacity-100 " />

        <div className="px-1  relative">
          <h2
            className={[
              `px-2 mt-20 `,
              ` pb-10`,
              `font-black`,
              `text-2xl`,
              `md:text-5xl`,
              `text-[white]`,
              `text-center`,
            ].join(` `)}
          >
            Get Premium. Get Better.
          </h2>

          <div className="block lg:flex justify-between md:mx-[4.5rem]">
            <div className="flex-1 m-1 md:m-20 text-2xl ">
              <span className="flex items-center ">
                <img className="w-20" src={crown.src} alt="Crown" />
                <h2 className="font-bold">
                  Level SuperMind
                  <br />
                  Premium Membership
                </h2>
              </span>

              <div className="pl-6 text-sm md:text-lg flex flex-col space-y-1 md:space-y-3 pt-3">
                <div className="flex items-center py-3 space-x-2">
                  <div>
                    <ArrowIcon />
                  </div>
                  <div>
                    Exclusive access to 700+ Mindfulness Content including
                    <br />
                    meditations, sleep stories, workouts, journal and music.
                  </div>
                </div>
                <div className="flex items-center py-3 space-x-2">
                  <div>
                    <ArrowIcon />
                  </div>
                  <div>Unlimited favourites and downloads.</div>
                </div>
                <div className="flex items-center py-3 space-x-2">
                  <div>
                    <ArrowIcon />
                  </div>
                  <div>Support for chromecast on your laptop and TV.</div>
                </div>
                <div className="flex items-center py-3 space-x-2">
                  <div>
                    <ArrowIcon />
                  </div>
                  <div>
                    Get access to the exclusive{` `}
                    <strong>community of premium users.</strong>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="flex-1 flex flex-col items-center pb-32 mx-3"> */}
            <div className="flex-1  pb-32 mx-3">
              {/* <div className=" "> */}
              <header
                className={[
                  `mx-auto`,
                  `mt-3`,
                  `mb-5`,
                  // `pt-8 md:pt-0`,
                  // `flex flex-col md:flex-row `, // Adjusted
                  `flex`, // Adjusted
                  `items-center`,
                  `justify-center`,
                  `max-w-5xl`,
                  `text-2xl`,
                ].join(` `)}
              >
                <button
                  onClick={() => setActivePlan(`DROID`)}
                  className={[
                    activePlan === `DROID` ? `font-bold` : ``,
                    `px-6`,
                    `md:px-14`,
                    `py-2`,
                    `flex`,
                    `flex-col`,
                    `items-center`,
                    // `md:mr-4`, // Adjusted
                  ].join(` `)}
                >
                  Android
                  <span
                    className={[
                      `h-[0.5rem]`,
                      `w-3/5`,
                      `bg-[#6e52df]`,
                      `rounded-lg`,
                      `transition-opacity`,
                      activePlan !== `DROID` ? `opacity-0` : `opacity-100`,
                    ].join(` `)}
                  />
                </button>

                <button
                  onClick={() => setActivePlan(`IOS`)}
                  className={[
                    activePlan === `IOS` ? `font-bold` : ``,
                    `px-6`,
                    `md:px-14`,
                    `py-2`,
                    `flex`,
                    `flex-col`,
                    `items-center`,
                  ].join(` `)}
                >
                  iOS
                  <span
                    className={[
                      `h-[0.5rem]`,
                      `w-3/5`,
                      `bg-[#6e52df]`,
                      `rounded-lg`,
                      `transition-opacity`,
                      activePlan !== `IOS` ? `opacity-0` : `opacity-100`,
                    ].join(` `)}
                  />
                </button>
              </header>

              <div className={[`m-auto`, `max-w-lg`].join(` `)}>
                {Object.keys(pricing).map((key) => (
                  <div
                    key={key}
                    className={[
                      activePlan === key ? `flex` : `hidden`,
                      `flex-col`,
                      `gap-y-10`,
                    ].join(` `)}
                  >
                    {pricing[key].map((p, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            boxShadow: `inset 0px 9px 20px #00000017, 0px 8px 16px #00000029`,
                          }}
                          className={[
                            ``,
                            `relative`,
                            `rounded-2xl  text-[white] bg-[#282234] border-2 border-[#362e48]`,
                          ].join(` `)}
                        >
                          <input
                            aria-label="pricing"
                            name={key}
                            type="radio"
                            defaultChecked={p.mostPopular}
                            className={[
                              `absolute`,
                              `inset-0`,
                              `border`,
                              `w-full`,
                              `h-full`,
                              `opacity-0`,
                              `cursor-pointer`,
                              cssClasses.radio,
                            ].join(` `)}
                          />

                          {/* "best deal" badge */}
                          {/* {p.isBestDeal && (
                            <span
                              className={[
                                `py-1`,
                                `px-3`,
                                `absolute`,
                                `bg-[white]`,
                                `border`,
                                `border-white`,
                                `left-[2rem]`,
                                `top-[-1rem]`,
                                `text-[#7356e8]`,
                                `text-sm`,
                                `rounded-xl`,
                                `z-[200px]`,
                                `border border-[#7356e8]`,
                              ].join(` `)}
                            >
                              BEST DEAL
                            </span>
                          )} */}

                          {/* "best deal" badge */}
                          {p.mostPopular && (
                            <span
                              className={[
                                `py-1`,
                                `px-3`,
                                `absolute`,
                                `bg-[#0F0622]`,
                                `border`,
                                `border-[#362E48]`,
                                `left-[2rem]`,
                                `top-[-1rem]`,
                                `text-[#F7D766]`,
                                `text-sm`,
                                `rounded-xl`,
                                `z-[200px]`,
                              ].join(` `)}
                            >
                              MOST POPULAR
                            </span>
                          )}

                          {/* "discount" badge */}
                          {/* {!!p.discountPercent && (
                          <span
                            className={[
                              `absolute`,
                              `top-[-3.2rem]`,
                              `right-[0.3rem]`,
                            ].join(` `)}
                          >
                            <DiscountSvg className="max-w-[5rem] md:max-w-[8rem] w-full z-30" />
                          </span>
                        )} */}

                          <div
                            className={[
                              `py-6`,
                              `px-0`,
                              `flex`,
                              `nowrap`,
                              `items-center`,
                              `rounded-xl`,
                            ].join(` `)}
                          >
                            <div className="px-6 md:px-10">
                              <span
                                className={[
                                  `block`,
                                  `w-[1.4em]`,
                                  `h-[1.4em]`,
                                  `bg-red-500`,
                                  `rounded-sm`,
                                  `flex`,
                                  `items-center`,
                                  `justify-center`,

                                  cssClasses.radio_btn_container,
                                ].join(` `)}
                              >
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className={cssClasses.check_icon}
                                />
                              </span>
                            </div>
                            <div
                              className={[
                                `flex`,
                                `flex-col`,
                                `text-md`,
                                `md:text-xl`,
                                `pr-10`,
                              ].join(` `)}
                            >
                              <span className="text-[white]">
                                {/* {p.paymentType === `monthly` && (
                                  <>
                                    <strong className="font-black">{`₹${p.price}`}</strong>
                                    <strong className="font-black text-xs">{`.00`}</strong>
                                    <strong className="font-black">{`/Month`}</strong>
                                    <br />
                                    <span className="text-sm">
                                      (Paid Monthly)
                                    </span>
                                  </>
                                )} */}
                                {/*  */}
                                {p.paymentType === `6-months` && (
                                  <>
                                    <strong className="font-black">{`₹199`}</strong>
                                    <strong className="font-black text-xs">{`.00`}</strong>
                                    <strong className="font-black">{`/Month`}</strong>

                                    <br />
                                    <span className="text-sm">
                                      (Paid Half Yearly)
                                    </span>
                                  </>
                                )}
                                {/*  */}
                                {p.paymentType === `annually` &&
                                  activePlan == `DROID` && (
                                    <>
                                      <strong className="font-black">{`₹149`}</strong>
                                      <strong className="font-black text-xs">{`.00`}</strong>
                                      <strong className="font-black">{`/Month`}</strong>
                                      <br />
                                      <span className="text-sm">
                                        (Paid Annually)
                                      </span>
                                    </>
                                  )}
                                {/*  */}
                                {p.paymentType === `annually` &&
                                  activePlan == `IOS` && (
                                    <>
                                      <strong className="font-black">{`₹149`}</strong>
                                      <strong className="font-black text-xs">{`.92`}</strong>
                                      <strong className="font-black">{`/Month`}</strong>

                                      <br />
                                      <span className="text-sm">
                                        (Paid Annually)
                                      </span>
                                    </>
                                  )}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
            {/* </div> */}
          </div>
          {/* 
          {isMobile && (
            <div className="mx-5 border border-red-400 -mt-20 md:-mt-0 md:w-80">
              <a href="https://lsm.lvl.fit/?data=guya8119qpa1psiuzwii">
                <button
                  className={cn(
                    `bg-gradient-to-r from-[#FFC607] via-[#ffeca9] to-[#F7D766] text-[#0F0622] text-lg font-extrabold hover:from-[#F7D766] hover:via-[#ffeca9] hover:to-[#FFC607] transform hover:scale-105 transition-all duration-500 p-3 hover:p-4 px-10 hover:px-14 my-3 rounded-3xl min-w-fit w-full h-14 max-w-xl md:text-xl md:my-4`,
                  )}
                >
                  Try Now
                </button>
              </a>
              <div className="text-sm font-thin text-center">
                No credit card required
              </div>
            </div>
          )} */}

          {/* {!isMobile && ( */}
          <div className="flex justify-center items-center -mt-20 pb-20">
            <div className="mx-5 md:w-80">
              <a href="https://click.lvl.fit/lFXn/ocmji2t4">
                <button
                  className={cn(
                    `bg-gradient-to-r from-[#FFC607] via-[#ffeca9] to-[#F7D766] text-[#0F0622] text-lg font-extrabold hover:from-[#F7D766] hover:via-[#ffeca9] hover:to-[#FFC607] transform hover:scale-105 transition-all duration-500 p-3 hover:p-4 px-10 hover:px-14 my-3 rounded-3xl min-w-fit w-full h-14 max-w-xl md:text-xl md:my-4 cursor-pointer`,
                  )}
                >
                  Try Now
                </button>
              </a>
              <div className="text-sm font-thin text-center">
                No credit card required
              </div>
            </div>
          </div>
          {/* )} */}
        </div>

        <div className="absolute bottom-0  w-full mt-40 md:mt-0 md:h-20  bg-gradient-to-t  from-level-purple to-transparent opacity-100 " />
      </div>
    </>
  );
}
