import React from 'react';
import { X, Check } from 'lucide-react';

interface ModalProps {
  page: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (email: string, phone: string) => void;
}

export function Modal({ page, isOpen, onClose, onSubmit }: ModalProps) {
  const [email, setEmail] = React.useState(``);
  const [phone, setPhone] = React.useState(``);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [errors, setErrors] = React.useState({
    email: false,
    phone: false,
  });

  if (!isOpen) return null;

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone: string) => {
    const phoneRegex = /^\d{10,14}$/;
    return phoneRegex.test(phone);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setErrors((prev) => ({
      ...prev,
      email: !validateEmail(value) && value.length > 0,
    }));
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, ``);
    if (value.length <= 14) {
      setPhone(value);
      setErrors((prev) => ({
        ...prev,
        phone: !validatePhone(value) && value.length > 0,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const isEmailValid = validateEmail(email);
    const isPhoneValid = validatePhone(phone);

    setErrors({
      email: !isEmailValid,
      phone: !isPhoneValid,
    });

    if (isEmailValid && isPhoneValid) {
      onSubmit(email, phone);
      setIsSubmitted(true);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center p-4">
      <div className="bg-[#1A1B1E] text-white rounded-lg max-w-md w-full p-6 relative">
        {!isSubmitted ? (
          <>
            <button
              onClick={onClose}
              className="absolute right-4 top-4 text-gray-400 hover:text-white"
              aria-label="Close"
            >
              <X size={24} />
            </button>

            <div className="text-center mb-6">
              <div className="mb-2">
                {page === `home`
                  ? `🏆 Google's Best App 2023`
                  : page === `course`
                  ? `⭐ Exclusive Offer`
                  : ``}
              </div>
              <h2 className="text-2xl font-bold mb-4">
                {page === `home`
                  ? `👋 Welcome to Level SuperMind`
                  : page === `course`
                  ? `Like What You See?`
                  : ``}
                <span className="text-lg block">
                  {page === `home` ? (
                    <>
                      Here&apos;s a {` `}
                      <span className="text-purple-400">50% Off</span> on
                      Premium for You
                    </>
                  ) : page === `course` ? (
                    `Keep Today's Offer`
                  ) : (
                    ``
                  )}
                </span>
              </h2>
              <p className="text-gray-300 mb-4">
                {page === `home`
                  ? `Get expert guidance, Meditations, Mantras, Workouts & Sleep for clear mind and better performance.`
                  : page === `course`
                  ? `Continue exploring while we hold your preferred offer and notify
                  you about more meditation tips and offers`
                  : ``}
              </p>
              <p className="text-purple-400 text-sm">
                {page === `home`
                  ? `Drop us your info & we'll save your discount`
                  : page === `course`
                  ? `Browse now, decide later – we'll save your offer`
                  : ``}
              </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <input
                  type="text"
                  placeholder={
                    page === `home`
                      ? `Email (secures your 50% discount)`
                      : page === `course`
                      ? `Email (saves today's offer for you)`
                      : ``
                  }
                  value={email}
                  onChange={handleEmailChange}
                  required
                  className={`w-full p-3 rounded bg-[#25262B] border ${
                    errors.email ? `border-red-500` : `border-gray-700`
                  } text-white placeholder-gray-400 focus:outline-none focus:border-purple-500`}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm mt-1">
                    Please enter a valid email address
                  </p>
                )}
              </div>
              <div>
                <input
                  type="text"
                  placeholder={
                    page === `home`
                      ? `Phone (for exclusive feature updates)`
                      : page === `course`
                      ? `Phone (for meditation tips & offers)`
                      : ``
                  }
                  value={phone}
                  onChange={handlePhoneChange}
                  required
                  className={`w-full p-3 rounded bg-[#25262B] border ${
                    errors.phone ? `border-red-500` : `border-gray-700`
                  } text-white placeholder-gray-400 focus:outline-none focus:border-purple-500`}
                />
                {errors.phone && (
                  <p className="text-red-500 text-sm mt-1">
                    Please enter valid phone number
                  </p>
                )}
              </div>
              <button
                type="submit"
                className="w-full py-3 px-4 bg-purple-500 hover:bg-purple-600 text-white rounded-md transition-colors"
              >
                {page === `home`
                  ? `Save 50% Discount`
                  : page === `course`
                  ? `Save Offer & Submit`
                  : ``}
              </button>
            </form>
          </>
        ) : (
          <>
            <button
              onClick={onClose}
              className="absolute right-4 top-4 text-gray-400 hover:text-white"
              aria-label="Close"
            >
              <X size={24} />
            </button>
            <div className="text-center">
              <div className="flex justify-center mb-6">
                <div className="w-16 h-16 rounded-full bg-purple-500 flex items-center justify-center">
                  <Check size={32} className="text-white" />
                </div>
              </div>
              <h2 className="text-3xl font-bold mb-4">
                Perfect! You&apos;re All Set
              </h2>
              <p className="text-gray-300 mb-6">
                We&apos;ll send your exclusive discount details via email and
                WhatsApp
              </p>
              <p className="text-purple-400 mb-8">
                Take your time exploring Level SuperMind
              </p>
              <button
                onClick={onClose}
                className="w-full py-3 px-4 bg-purple-500 hover:bg-purple-600 text-white rounded-md transition-colors"
              >
                Continue Exploring
              </button>
              <div className="mt-8 text-center">
                <div className="inline-block px-4 py-2 bg-[#25262B] rounded-full">
                  🏆 Google&apos;s Best App 2023
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Modal;
