import React from 'react';
import { Nav } from '@/components/nav';
import { Hero } from '@/components/hero';
import { Meta } from '@/components/meta';
import { Pr } from '@/components/pr';
import { useWindowDimension } from '@/hooks';
import { KeyFeatures } from '@/components/key-features';
import { ContactForm } from '@/components/contact-form';
import { DownloadApp } from '@/components/download-app';
import { Testimonials } from '@/components/testimonials';
import { PricingPlans } from '@/components/pricing-plan';
import { CircleShapeBox } from '@/components/circle-shape';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrandCollabs } from '@/components/brand-collabs';
import AboutComp from '@/components/about-comp';
import Awards from '@/components/awards';
import { socialProfiles } from '@/config/socialProfile';
import Blackfriday from '@/components/Blackfridaybar';
import NewYear from '@/components/Newyearbar';
import { Modal } from '@/components/Modal';
import jwt from 'jsonwebtoken';
import axios from 'axios';
// import Mahashivratri from '@/components/Blackfridaybar';
export default function Home() {
  const [showModal, setShowModal] = React.useState(false);
  const [popUpCount, setPopUpCount] = React.useState(1);
  const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);
  const winDim = useWindowDimension();

  const innerWidth = winDim?.innerWidth || 900;
  const [unsubFormResp, setUnsubFormResp] = React.useState({
    unsubscribed: false,
    message: ``,
  });

  React.useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    setUnsubFormResp(() => ({
      unsubscribed: !!search.get(`unsubscribed`),
      message: search.get(`message`) || ``,
    }));
  }, []);

  React.useEffect(() => {
    // Check if user has already filled the form
    const isFilledByUser =
      localStorage.getItem(`isPopUpFilledByUser`) === `true`;
    if (isFilledByUser) return;

    // Check if popup was shown in current session
    const isPopUpShown = sessionStorage.getItem(`isPopUpShown`) === `true`;
    if (isPopUpShown) return;

    const handleLoad = () => {
      // Start the timer only after everything has loaded
      const timer = setTimeout(() => {
        setShowModal(true);
      }, 7000);

      return () => clearTimeout(timer); // Cleanup if the component unmounts
    };

    // Check if the page is already loaded (for cases where useEffect runs after load)
    if (document.readyState === `complete`) {
      handleLoad();
    } else {
      window.addEventListener(`load`, handleLoad);
    }

    return () => {
      window.removeEventListener(`load`, handleLoad);
    };
  }, []);

  React.useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  const handleCloseModal = () => {
    setShowModal(false);

    const isPopUpShown = sessionStorage.getItem(`isPopUpShown`);
    const isPopUpFilledByUser = localStorage.getItem(`isPopUpFilledByUser`);

    if (!isPopUpShown && isPopUpFilledByUser) return;

    if (!isPopUpShown && !isPopUpFilledByUser) {
      setShowModal(false);
      sessionStorage.setItem(`isPopUpShown`, `true`);

      let id: NodeJS.Timeout | null = null;
      if (popUpCount < 2) {
        id = setTimeout(() => {
          setPopUpCount(2);
          setShowModal(true);
          sessionStorage.removeItem(`isPopUpShown`);
        }, 60000);
      }

      if (id) setTimeoutId(id);
    }
  };

  const postPopUp = async (token: string) => {
    try {
      const response = await axios.post(
        `https://level-core-backend.api.level.game/v1/moengage/create`,
        {}, // Request body (empty in this case)
        {
          headers: {
            Authorization: `auth ${token}`,
            'Content-Type': `application/json`,
          },
        },
      );

      console.log(`Form submission successful:`, response.data);
    } catch (error: any) {
      console.error(
        `Form submission failed:`,
        error.response ? error.response.data : error.message,
      );
    }
  };

  const handleSubmitModal = (email: string, phone: string) => {
    // Handle form submission here
    const payload = {
      email,
      phone,
      type: `website_visitor_Homepage`,
    };
    const secretKey = `LVB97M-lKA1-bGcwNLRYXySuThn3pTYKZIN9CRYsBvY`;

    if (secretKey) {
      const token = jwt.sign(payload, secretKey, { expiresIn: `1h` });
      postPopUp(token);
    } else {
      console.log(`SECRET_KEY is not defined`);
    }

    localStorage.setItem(`isPopUpFilledByUser`, `true`);
  };

  return (
    <>
      <Nav />

      {/* mail unsubscribe confirmation */}
      {unsubFormResp.unsubscribed && unsubFormResp.message && (
        <div className={[`max-w-7xl`, `mx-auto`, `my-6`, `px-2`].join(` `)}>
          <div
            className={[
              `text-[#141414]`,
              `border-4`,
              `border-[#7356E850]`,
              `rounded-2xl`,
              `p-4`,
              `text-2xl`,
              `font-thin`,
              `text-center`,
            ].join(` `)}
          >
            {unsubFormResp.message}
          </div>
        </div>
      )}

      <Hero />
      <Meta />

      <AboutComp />
      <Awards />

      <div className="z-[30]">
        <KeyFeatures />
      </div>

      <BrandCollabs />

      <div className="relative overflow-hidden">
        <Pr />
      </div>

      <div className="relative overflow-hidden">
        <PricingPlans />
        {innerWidth > 900 ? (
          <CircleShapeBox className={[`absolute`, `inset-0`].join(` `)} />
        ) : null}
      </div>

      <Testimonials />

      <ContactForm />

      <div
        className={[`bg-level-purple`, `py-4 md:py-20`, `px-4`, `my-20`].join(
          ` `,
        )}
      >
        <h2
          className={[
            `px-2`,
            `font-black`,
            `md:text-4xl text-3xl`,
            `text-[white]`,
            `text-center`,
          ].join(` `)}
        >
          Join A Community Of SuperMinds
        </h2>

        <div
          className={[
            `mt-10`,
            `mx-auto`,
            `max-w-5xl`,
            `flex`,
            `gap-x-16`,
            `gap-y-14`,
            `items-center`,
            `justify-center`,
            `flex-wrap`,
          ].join(` `)}
        >
          {socialProfiles.map((profile) => (
            <a
              key={profile.name}
              href={profile.permalink}
              className="text-[white]"
              aria-label="Level Social Media"
            >
              <FontAwesomeIcon
                icon={profile.faIcon}
                fontSize="4.4em"
                aria-label="social icon"
              />
            </a>
          ))}
        </div>
      </div>

      <Modal
        page={`home`}
        isOpen={showModal}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
      />
    </>
  );
}
