/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @next/next/no-img-element */
'use client';

import React, { useRef } from 'react';
import main from '../../assets/new-assets/home/hero/main.webp';

import { cn } from '@/utils';
import scrollDown from '../../assets/new-assets/animation/scrollDown.json';

// import mainAnimation from '../../assets/new-assets/animation/mainAnimationHighRes.json';
// import mainAnimation from '../../assets/new-assets/animation/newAnimation.json';
import mainAnimation from '../../assets/new-assets/animation/Website_Animation_Updated.json';
import landing1 from '../../assets/new-assets/home/hero/landing-1.webp';
import dynamic from 'next/dynamic';
import { useMobileResponsive } from '@/hooks';
import qrImage from '../../assets/new-assets/home/hero/qrImageOrg.png';

const Lottie = dynamic(() => import(`react-lottie-player`), { ssr: false });

export function Hero() {
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleScrollDown = () => {
    if (scrollRef.current) {
      window.scrollTo({
        top: scrollRef.current?.offsetTop,
        behavior: `smooth`,
      });
    }
  };

  const { isMobile, windowWidth } = useMobileResponsive();

  return (
    <>
      <div
        className={cn(
          `bg-[#0f0622] text-white block  justify-between items-center  
        md:flex relative
        `,
        )}
        style={{
          backgroundImage: `url(${landing1.src})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div
          className={cn(
            `w-full flex-1 my-auto text-center md:text-left h-3/4 flex flex-col justify-center items-center
          md:w-1/2 md:h2/4  p-2 xl:ml-32 `,
          )}
        >
          <div
            className={cn(`
             p-4 z-30
          `)}
          >
            <h1
              className=" text-3xl   lg:text-4xl xl:text-6xl font-extrabold leading-snug
            space-y-1 "
            >
              <div>CLEAR MIND.</div>
              <div>BETTER PERFORMANCE.</div>
            </h1>
            <div
              className="py-4  font-light leading-normal text-xl
            space-y-1 md:space-y-3 "
            >
              Reduce your stress, anxiety & overthinking with expertly curated
              activities for your daily routine.
            </div>
            <br />
            <div className=" md:w-80 pb-28 md:pb-0 ">
              {isMobile ? (
                <a href="https://click.lvl.fit/lFXn/ocmji2t4">
                  <button
                    className={cn(
                      `bg-gradient-to-r from-[#FFC607] via-[#ffeca9]  to-[#F7D766] text-[#0F0622] text-lg  font-extrabold 
                  hover:from-[#F7D766] hover:via-[#ffeca9]  hover:to-[#FFC607] 
                transition-all duration-500
                p-3 px-10 hover:px-14 my-3 transform hover:scale-105
                rounded-3xl min-w-fit w-full max-w-xl
                md:text-xl md:my-4 
                `,
                    )}
                  >
                    Try Now
                  </button>
                </a>
              ) : (
                <>
                  <div className="p-5 mt-5 w-96 flex items-center space-x-2 bg-gradient-to-br from-[#2A105F] to-[#0F0622] border border-[#CDCDCD] rounded-3xl">
                    <img alt="Qr code" src={qrImage.src} className="w-24" />

                    <div className="space-y-1">
                      <span className="font-bold text-xl">
                        Rated Best App of 2023 by Google
                      </span>
                      <p>Scan to Download Level SuperMind</p>
                    </div>
                  </div>
                </>
              )}
              <div className="pt-3 text-sm font-thin text-center">
                No credit card required
              </div>
            </div>
          </div>
          <br />

          {!isMobile && (
            <div
              onClick={handleScrollDown}
              className="absolute bottom-0 left-1/2 transform -translate-x-1/2 z-10 flex flex-col items-center justify-center cursor-pointer"
            >
              <span className="text-center font-normal ">
                Scroll for a SuperMind
              </span>
              <Lottie
                loop
                play
                style={{ width: 100, height: 100 }}
                animationData={scrollDown}
              />
            </div>
          )}
        </div>

        {/* Image Comp */}
        <div
          className={cn(`relative flex-1 -mt-52 sm:-mt-0`, {
            '-mt-40 ': windowWidth > 440,
            '-mt-32': windowWidth > 540,
            '-mt-28': windowWidth > 600,
          })}
          style={{
            backgroundImage: `url(${main.src})`,
            backgroundSize: `cover`,
            backgroundPosition: `center`,
            backgroundRepeat: `no-repeat`,
          }}
        >
          <div className="absolute top-0 md:left-0 w-full h-20 md:w-20 md:h-full bg-gradient-to-b md:bg-gradient-to-r from-level-purple to-transparent opacity-100 " />
          <div className="flex justify-center items-center pt-10  md:pt-0 sm:h-[90vh]">
            <div className="p-8 ">
              <Lottie
                loop
                play
                animationData={mainAnimation}
                className="
                p-0 
                lg:p-10
                xl:p-10 
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div ref={scrollRef} />
    </>
  );
}

{
  /*
  
    const reducer = (state: any, action: { type: any }) => {
    switch (action.type) {
      case `MEDITATE`:
        return { comp: `Meditate` };
      case `WORKOUTS`:
        return { comp: `Stay fit` };
      case `SLEEP`:
        return { comp: `Sleep Better` };
      case `JOURNAL`:
        return { comp: `Express yourself` };
      default:
        return state;
    }
  };

  const initialState = { comp: `Meditate` };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const types = [`WORKOUTS`, `MEDITATE`, `SLEEP`, `JOURNAL`];
    let index = 0;
    let timeout: NodeJS.Timeout;

    const startTransition = () => {
      dispatch({ type: types[index] });
      index = (index + 1) % types.length;
      timeout = setTimeout(startTransition, 7000);
    };

    startTransition();

    return () => clearTimeout(timeout);
  }, []);
  
  <div className="" style={{ width: `30%` }}>
              <div className="space-x-2 w-full bg-level-purple h-16 p-4 mb-9 rounded-2xl flex items-center">
                {state.comp === `Meditate` && (
                  <img
                    className={cn(`w-10`, {
                      'fade-in-animation': state.comp === `Meditate`,
                      'fade-out-animation': state.comp !== `Meditate`,
                    })}
                    src={greenCircle.src}
                    alt="meditation img"
                  />
                )}
                {state.comp === `Sleep Better` && (
                  <img
                    className={cn(`w-10`, {
                      'fade-in-animation': state.comp === `Sleep Better`,
                      'fade-out-animation': state.comp !== `Sleep Better`,
                    })}
                    src={blueCircle.src}
                    alt=""
                  />
                )}
                {state.comp === `Express yourself` && (
                  <img
                    className={cn(`w-10`, {
                      'fade-in-animation': state.comp === `Express yourself`,
                      'fade-out-animation': state.comp !== `Express yourself`,
                    })}
                    src={pinkCircle.src}
                    alt=""
                  />
                )}
                {state.comp === `Stay fit` && (
                  <img
                    className={cn(`w-10`, {
                      'fade-in-animation': state.comp === `Stay fit`,
                      'fade-out-animation': state.comp !== `Stay fit`,
                    })}
                    src={yellowCircle.src}
                    alt=""
                  />
                )}
                <div>
                  <h2
                    className={cn(
                      ` z-10 text-sm
                        md:text-xl 
                `,

                      {
                        ' fade-in-animation':
                          state.comp === `Meditate` ||
                          state.comp === `Sleep Better` ||
                          state.comp === `Express yourself`,
                      },
                      {
                        ' fade-in-animation': state.comp === `Stay fit`,
                      },
                    )}
                  >
                    {state.comp}
                  </h2>
                </div>
              </div>

              <div className=" bg-black rounded-3xl">
                {state.comp === 'Meditate' && (
                  <Image
                    className={cn('', {
                      'fade-in-animation': state.comp === 'Meditate',
                      'fade-out-animation': state.comp !== 'Meditate',
                    })}
                    src={meditateImg}
                    alt="Level Supermind Screens screen"
                    style={{ zIndex: 1 }} // Ensure it appears on top of phoneBg
                  />
                )}

                {state.comp === `Sleep Better` && (
                  <Image
                    className={cn({
                      'fade-in-animation': state.comp === `Sleep Better`,
                      'fade-out-animation': state.comp !== `Sleep Better`,
                    })}
                    src={sleepImg}
                    alt="Level Supermind Screens screen"
                  />
                )}
                {state.comp === `Express yourself` && (
                  <Image
                    className={cn({
                      'fade-in-animation': state.comp === `Express yourself`,
                      'fade-out-animation': state.comp !== `Express yourself`,
                    })}
                    src={journalImg}
                    alt="Level Supermind Screens screen"
                  />
                )}
                {state.comp === `Stay fit` && (
                  <Image
                    className={cn(`rounded-2xl`, {
                      'fade-in-animation': state.comp === `Stay fit`,
                      'fade-out-animation': state.comp !== `Stay fit`,
                    })}
                    src={workoutImg}
                    alt="Level Supermind Screens screen"
                  />
                )}
              </div>
            </div> */
}
